import React, { useState } from 'react';

const GalleryOne = ({ images }) => {
 const [currentIndex] = useState(0);

 const getCurrentSlide = () => {
  const slide = images[currentIndex];
  const backgroundStyle = { backgroundColor: slide.bgcolor };
  if (slide.type === 'image') {
   return (
    <img
     src={`../img/${slide.content}`}
     alt={`картинка`}
     // loading={`lazy`}
     style={{
      width: '100%',
      height: '80vh',
      /* minHeight: '25vh', */
      objectFit: 'contain',
      objectPosition: 'center',
      display: 'block',
      ...backgroundStyle,
     }}
    />
   );
  } else if (slide.type === 'text') {
   return (
    <div style={{
     height: '80vh',
     padding: '4.16vh 8.33vw 8.33vh 8.33vw',
     objectPosition: 'center сenter',
     display: 'flex',
     alignItems: 'center',
     ...backgroundStyle,
    }}
    >
     <div style={{ maxWidth: '800px' }}>{slide.content}</div>
    </div >
   );
  } else if (slide.type === 'video') {
   return (
    <video
     src={`../img/${slide.content}`}
     alt={`видео`}
     // loading={`lazy`}
     style={{
      width: '100%',
      height: '80vh',
      objectFit: 'contain',
      objectPosition: 'center',
      display: 'block',
      ...backgroundStyle,
     }}
     autoPlay
     loop
    /* muted */
    /* controls */
    />
   );

  } else if (slide.type === 'video_noloop') {
   return (
    <video
     src={`../img/${slide.content}`}
     alt={`видео`}
     style={{
      width: '100%',
      height: '80vh',
      objectFit: 'contain',
      objectPosition: 'center',
      display: 'block',
      ...backgroundStyle,
     }}
     autoPlay
     /* loop */
    /* muted */
    /* controls */
    />
   );
  }
  return null;
 };

 return (
  <div style={{ maxWidth: '100vw' }}>
   <div>{getCurrentSlide()}</div>
  </div>
 );
};

export default GalleryOne;