import React, { useState, useEffect } from "react";
import Menu from "../../../Menu";
import "../../../index.css";
import "../../page.css";
import copy from "clipboard-copy";

const images = [
 "001",
 "006",
 "008",
 "010",
 "012",
 "020",
 "025",
 "026",
 "029",
 "034",
 "054",
 "056",
 "058",
 "064",
 "085",
 "091",
 "093",
 "101",
 "111",
];

const imageObjects = {};
images.forEach((img) => {
 imageObjects[img] = require(`./${img}.png`);
});

const SelectionBear = () => {
 const [pairs, setPairs] = useState(generatePairs(images.length));
 const [currentPairIndex, setCurrentPairIndex] = useState(0);
 const [ratings, setRatings] = useState(Array(images.length).fill(0));
 const [tableData, setTableData] = useState(generateTableData());
 const [pairsSelected, setPairsSelected] = useState(0);
 const [isCopied, setIsCopied] = useState(false);

 useEffect(() => {
  const handleKeyPress = (event) => {
   if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
    if (pairs.length > 0) {
     const selectedImageIndex =
      event.key === "ArrowLeft"
       ? pairs[currentPairIndex][0]
       : pairs[currentPairIndex][1];
     handleImageClick(selectedImageIndex);
    }
   }
  };

  document.addEventListener("keydown", handleKeyPress);

  return () => {
   document.removeEventListener("keydown", handleKeyPress);
  };
 }, [currentPairIndex, pairs]);

 function generatePairs(length) {
  const pairs = [];
  for (let i = 0; i < length; i++) {
   for (let j = i + 1; j < length; j++) {
    pairs.push([i, j]);
   }
  }
  return shuffleArray(pairs); // Shuffle the pairs
 }

 function shuffleArray(array) {
  const shuffledArray = array.slice(); // Create a copy of the original array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
   const j = Math.floor(Math.random() * (i + 1));
   [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
 }

 function generateTableData() {
  // Генерируем таблицу
  const currentDate = new Date()
   .toISOString()
   .replace(/T/, " ")
   .replace(/\..+/, "");

  const originalFileNames = images.map((img) => {
   // Извлекаем имена изображений
   const numPart = img.replace("img", ""); // Извлекаем числовую часть из имени
   return numPart; // Возвращаем имя
  });

  const data = images.map((img, index) => ({
   filename: originalFileNames[index], // Имя изображения
   rating: ratings[index], // Рейтинг
  }));
  return [{ date: currentDate }, ...data]; // Добавляем дату и время
 }

 const handleImageClick = (selectedImageIndex) => {
  // Обработчик клика
  const [imageAIndex, imageBIndex] = pairs[currentPairIndex]; // Индексы изображений
  const newRatings = [...ratings]; // Копируем рейтинг

  if (selectedImageIndex === imageAIndex) {
   // Если выбранное изображение соответствует первому изображению
   newRatings[imageAIndex]++; // Увеличиваем рейтинг
  } else {
   // Если выбранное изображение не соответствует первому изображению
   newRatings[imageBIndex]++; // Увеличиваем рейтинг
  }

  setRatings(newRatings); // Обновляем рейтинг
  setPairsSelected(pairsSelected + 1); // Увеличиваем количество выбранных пар

  const remainingPairs = pairs.slice(currentPairIndex + 1); // Оставшиеся пары
  setPairs(remainingPairs); // Обновляем пары

  if (remainingPairs.length === 0) {
   // Если все пары выбраны
   setTableData(generateTableData()); // Генерируем таблицу
   setCurrentPairIndex(null); // Сбрасываем индекс текущей пары
  } else {
   // Если есть оставшиеся пары
   setCurrentPairIndex(0); // Сбрасываем индекс текущей пары
  }
 };

 const handleCopyTableData = () => {
  const tableString =
   `${tableData[0].date}\n` + // Добавляем дату и время
   tableData
    .slice(1) // Исключаем первую строку
    .map((row) => `${row.filename}_${row.rating}`) // Добавляем имя изображения и рейтинг
    .join("\n"); // Соединяем строки

  copy(tableString);
  setIsCopied(true);
 };

 const renderImages = () => {
  const [imageAIndex, imageBIndex] = pairs[currentPairIndex];
  return (
   <div className="container">
    <div
     className="content"
     style={{
      height: "auto",
      gap: "10vh",
      width: "100vw",
     }}
    >
     <p style={{ textAlign: "center" }}>
      выбор варианта
      <br />
      клавишами ← →
      <br />
      или тапом
     </p>
     <div style={{ display: "flex", gap: "0px" }}>
      <img
       src={imageObjects[images[imageAIndex]]}
       alt={`Image A - ${images[imageAIndex]}`}
       onClick={() => handleImageClick(imageAIndex)}
       style={{ width: "200px", height: "200px", cursor: "pointer" }}
      />
      <img
       src={imageObjects[images[imageBIndex]]}
       alt={`Image B - ${images[imageBIndex]}`}
       onClick={() => handleImageClick(imageBIndex)}
       style={{ width: "200px", height: "200px", cursor: "pointer" }}
      />
     </div>
     <div style={{ marginTop: "10px" }}>
      {/* всего картинок */}
      вариантов: {images.length}
      <br />
      отобрано пар: {pairsSelected}/{(images.length * (images.length - 1)) / 2}
     </div>
    </div>
   </div>
  );
 };

 const renderTable = () => (
  <div>
   {isCopied && (
    <div style={{ marginBottom: "10px", textAlign: "center" }}>
     <a
      href="https://t.me/philippsedov"
      target="_blank"
      rel="noopener noreferrer"
     >
      <button className="buttonCV" style={{ width: "100%" }}>
       Отправьте
       <br />
       скопированное
       <br />
       перейдя по ссылке
      </button>
     </a>
    </div>
   )}
   <button
    onClick={handleCopyTableData}
    className="buttonCV"
    style={{ marginBottom: "2ch" }}
   >
    Скопировать результат
   </button>

   <table style={{ width: "100%" }}>
    <thead>
     <tr>
      <th>№</th>
      <th>💖</th>
     </tr>
    </thead>
    <tbody>
     {tableData.slice(1).map((row, index) => (
      <tr key={index}>
       <td>{row.filename}</td>
       <td>{row.rating}</td>
      </tr>
     ))}
    </tbody>
   </table>
  </div>
 );

 return (
  <div className="container">
   <Menu />
   <div
    className="content"
    style={{
     paddingTop: "10vh",
     height: "auto",
     gap: "10vh",
     width: "100vw",
    }}
   >
    {pairs.length > 0 ? renderImages() : renderTable()}
   </div>
  </div>
 );
};

export default SelectionBear;
