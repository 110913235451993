import React from "react";
import Menu from "../Menu";
import Gallery from "../Gallery";
import GalleryOne from "../GalleryOne";
import images from "../images";

class Graphics extends React.Component {
 handleMenuToggle = () => {
  this.setState((prevState) => ({
   isMenuVisible: !prevState.isMenuVisible,
  }));
 };

 componentDidMount() {
  window.scrollTo(0, 0); // Вставьте код сброса положения прокрутки здесь
 }
 render() {
  return (
   <div>
    <Menu />
    <div className="content">
     {/* Aviasales */}
     <Gallery images={images.filter((item) => item.project === "Aviasales")} />

     {/* ВГТРК */}
     <Gallery images={images.filter((item) => item.project === "ВГТРК")} />

     {/* OZON */}
     <Gallery images={images.filter((item) => item.project === "OZON")} />

     {/* Сбер-сказка */}
     <Gallery images={images.filter((item) => item.project === "01")} />
     <a
      href="https://www.figma.com/proto/F6QyQk574UiWhcxLGn5WPI/20231214_PhilippSedov_SBER?page-id=0%3A1&type=design&node-id=25-160&t=gSLxCdUmoCtzVUMD-0&scaling=contain&starting-point-node-id=25%3A160&hide-ui=1"
      target="_blank"
     >
      прототип
     </a>

     {/* rive */}
     <div className="gallery2">
      <GalleryOne images={images.filter((item) => item.project === "rive1")} />
      <GalleryOne images={images.filter((item) => item.project === "rive2")} />
     </div>

     {/* видео */}
     <iframe
      // ПИК
      width="100%"
      height="800"
      src="https://www.youtube.com/embed/QbjVB7YUHuA"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
     ></iframe>

     <iframe
      // древний шоурил
      src="https://player.vimeo.com/video/285255088?h=25c3455741"
      width="100%"
      height="640"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
     ></iframe>

     <iframe
      // авто
      src="https://player.vimeo.com/video/214308776?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
      width="100%"
      height="800"
      frameborder="0"
      allow="autoplay; fullscreen"
     ></iframe>

     <iframe
      // Marcus
      src="https://player.vimeo.com/video/279949659?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
      width="100%"
      height="800"
      frameborder="0"
      allow="autoplay; fullscreen"
     ></iframe>

     {/* Ростелеком и логотипы */}
     <Gallery images={images.filter((item) => item.project === "logo")} />
     {/* CarPrice_ad */}
     <Gallery
      images={images.filter((item) => item.project === "CarPrice_ad")}
     />
     {/* Game Insight */}
     <Gallery images={images.filter((item) => item.project === "comics")} />
     {/* Музей Москвы */}
     <Gallery images={images.filter((item) => item.project === "масленица")} />
     <Gallery
      images={images.filter((item) => item.project === "20211001_poster_mm_1")}
     />
     <Gallery
      images={images.filter((item) => item.project === "20211001_poster_mm_2")}
     />
     <Gallery
      images={images.filter((item) => item.project === "20211001_poster_mm_3")}
     />
     <Gallery
      images={images.filter((item) => item.project === "poster_layout")}
     />

     <Gallery images={images.filter((item) => item.project === "ill")} />

     <Gallery images={images.filter((item) => item.project === "video")} />
     <p
      style={{
       backgroundColor: "white",
       height: "20vh",
       display: "flex",
       justifyContent: "center",
       alignItems: "center",
      }}
     >
      больше в 
      <a
       href="https://www.instagram.com/philippsedov/"
       target="_blank"
       rel="noreferrer"
      >
       instagram
      </a>
     </p>
    </div>
   </div>
  );
 }
}

export default Graphics;
