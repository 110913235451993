import React from "react";
import Ava from "../src/img/ava.png";
import Btn from "./Btn";
// import BackgroundButton from "./BackgroundButton";
import "./index.css";

class AvaImage extends React.Component {
 render() {
  return (
   <div className="avaContainer">
    <a
     href="https://t.me/philippsedovchannel"
     target="_blank"
     rel="noopener noreferrer"
    >
     <img className="ava" src={this.props.image} alt="аватарка" />
    </a>
   </div>
  );
 }
}

function Menu() {
 return (
  <div>
   {/* слева */}
   <div className="menuHeader">
    <Btn
     href="../cv"
     target="_blank"
     rel="noopener noreferrer"
     className="buttonCV"
     style={{ backgroundColor: "blue", color: "white" }}
    >
     cv
    </Btn>

    <Btn
     href="https://t.me/philippsedov"
     target="_blank"
     rel="noopener noreferrer"
     className="button"
    >
     telegram
    </Btn>

    <AvaImage image={Ava} />
    {/* <BackgroundButton /> */}
   </div>

   {/* справа */}
   <div className="menuFilter">
    <Btn className="button" href="/gui">
     gui
    </Btn>
    {/* <Btn className="button" href="../animation"><div className="buttonAnimation">анимация</div></Btn> */}
    <Btn className="button" href="../graphic">
     графика
    </Btn>
    <Btn className="button" href="../zabavki">
     <div className="buttonAnimation">забавки</div>
    </Btn>
   </div>
  </div>
 );
}

export default Menu;
