const images = [
 // 01
 {
  type: "image",
  content: "./01/20231214_PhilippSedov_01.webp",
  project: "01",
  bgcolor: "#000",
  category: "gui",
 },
 {
  type: "image",
  content: "./01/20231214_PhilippSedov_02.webp",
  project: "01",
  bgcolor: "#000",
  category: "gui",
 },
 {
  type: "image",
  content: "./01/20231214_PhilippSedov_03.webp",
  project: "01",
  bgcolor: "#000",
  category: "gui",
 },
 {
  type: "image",
  content: "./01/20231214_PhilippSedov_04.webp",
  project: "01",
  bgcolor: "#000",
  category: "gui",
 },
 {
  type: "image",
  content: "./01/20231214_PhilippSedov_05.webp",
  project: "01",
  bgcolor: "#000",
  category: "gui",
 },
 {
  type: "image",
  content: "./01/20231214_PhilippSedov_06.webp",
  project: "01",
  bgcolor: "#000",
  category: "gui",
 },
 {
  type: "image",
  content: "./01/20231214_PhilippSedov_07.webp",
  project: "01",
  bgcolor: "#000",
  category: "gui",
 },
 {
  type: "image",
  content: "./01/20231214_PhilippSedov_08.webp",
  project: "01",
  bgcolor: "#000",
  category: "gui",
 },
 {
  type: "image",
  content: "./01/20231214_PhilippSedov_09.webp",
  project: "01",
  bgcolor: "#000",
  category: "gui",
 },
 {
  type: "image",
  content: "./01/20231214_PhilippSedov_10.webp",
  project: "01",
  bgcolor: "#000",
  category: "gui",
 },
 

 
 /* xtalk.xhat 2022-2023*/
 {
  type: "image",
  content: "xtalk.webp",
  project: "xtalk",
  bgcolor: "#FF005C",
  category: "gui",
 },
 {
  type: "text",
  content:
   "xtalk.chat – cекстинг платформа: анализ ･ фирстиль ･ ux ･ gui ･ метрики ･ реклама. Выявил основную задачу, как увеличение конверсии во второй контакт. Проанализировал яндекс.метрику, изучил специфику отрасли и ца, сформировал фирстиль, спроектировал cjm и интерфейс, ux-копирайтинг, способы online и offline рекламы и её вёрстку. Процент второго контакта увеличился и продолжает расти. А рекламу ещё толком не запускали)",
  project: "xtalk",
  bgcolor: "#FFF",
  category: "gui",
 },
 {
  type: "image",
  content: "xtalk_2.webp",
  project: "xtalk",
  bgcolor: "#FFF",
  category: "gui",
 },
 {
  type: "image",
  content: "xtalk_3.webp",
  project: "xtalk",
  bgcolor: "#FFF",
  category: "gui",
 },

 /* logo */
 /* 2mood */
 {
  type: "image",
  content: "2mood.webp",
  project: "logo",
  bgcolor: "#FFF",
 },

 {
  type: "image",
  content: "logo_ugra.png",
  project: "logo",
  bgcolor: "#fff",
 },
 {
  type: "image",
  content: "logo_rostelecom.png",
  project: "logo",
  bgcolor: "#fff",
 },

 /* agro */
 {
  type: "image",
  content: "logo_agro.png",
  project: "logo",
  bgcolor: "#fff",
 },

 /* icon_jjo */
 {
  type: "image",
  content: "icon_jjo.png",
  project: "logo",
  bgcolor: "#fff",
 },

 /* volna */
 {
  type: "image",
  content: "logo_2023_volna_v1.jpeg",
  project: "logo",
  bgcolor: "#fff",
 },
 {
  type: "image",
  content: "logo_2023_volna_var.jpeg",
  project: "logo",
  bgcolor: "#fff",
 },
 /* logo_in_game */
 {
  type: "image",
  content: "logo_in_game.jpg",
  project: "logo",
  bgcolor: "#fff",
 },

 /* mossight */
 {
  type: "image",
  content: "mossight.jpg",
  project: "logo",
  bgcolor: "#FE0000",
 },
 {
  type: "text",
  content: "Умею в логотипы, айдентику, анимацию, графику, 3D",
  project: "logo",
  bgcolor: "#FFF",
 },

 /* анимация иконок */
 {
  type: "image",
  content: "cart.gif",
  project: "rive1",
  bgcolor: "#FF0",
 },
 {
  type: "image",
  content: "basket.gif",
  project: "rive2",
  bgcolor: "#F0F",
 },
 /* масленица */
 {
  type: "image",
  content: "poster_mm_maslenica.png",
  project: "масленица",
  bgcolor: "#dcff9e",
 },
 {
  type: "image",
  content: "poster_mm_maslenica_2.jpeg",
  project: "масленица",
  bgcolor: "#dcff9e",
 },
 {
  type: "image",
  content: "poster_mm_maslenica_3.jpeg",
  project: "масленица",
  bgcolor: "#dcff9e",
 },
 {
  type: "image",
  content: "poster_mm_maslenica_4.jpeg",
  project: "масленица",
  bgcolor: "#dcff9e",
 },
 {
  type: "image",
  content: "poster_mm_maslenica_5.jpeg",
  project: "масленица",
  bgcolor: "#dcff9e",
 },
 {
  type: "image",
  content: "poster_mm_maslenica_6.jpeg",
  project: "масленица",
  bgcolor: "#dcff9e",
 },
 {
  type: "image",
  content: "poster_mm_maslenica_7.jpeg",
  project: "масленица",
  bgcolor: "#dcff9e",
 },

 /* ABB 2022 */
 {
  type: "image",
  content: "abb_1_1.webp",
  project: "ABB",
  bgcolor: "#FF4E4E",
 },
 {
  type: "text",
  content:
   "ARMENIAN BUSINESS BANK: изменил архитектуру вложености, сделал прототип для теста на скорость прохождения сценариев.\n\nРезультат: ускорение на 10%–76%.\nОплата по реквизитам -10%;\nПеревод между счетами -34%;\nНаписать и отправить письмо в тех.поддержку -47%;\nИстория операций -76%",
  project: "ABB",
  bgcolor: "#FFF",
 },
 {
  type: "text",
  content:
   "+ Выяснил что скорость составления выписки занимает время, уведомлений о готовности нет, а скачивание сложно. Поэтому решил сценарий добавив экран с уведомлениями и быстрым скачиванием из него",
  project: "ABB",
  bgcolor: "#FFF",
 },
 {
  type: "image",
  content: "abb_1_2.webp",
  project: "ABB",
  bgcolor: "#FF4E4E",
 },

 /* ABB адаптив 2022 */
 {
  type: "text",
  content: "+ Сделал адаптивку текущей архитектуры и облегчил gui",
  project: "ABB",
  bgcolor: "#FFF",
 },
 {
  type: "image",
  content: "abb_3_1.webp",
  project: "ABB",
  bgcolor: "#FF4E4E",
 },
 {
  type: "image",
  content: "abb_3_2.png",
  project: "ABB",
  bgcolor: "#FF4E4E",
 },
 {
  type: "image",
  content: "abb_3_3.png",
  project: "ABB",
  bgcolor: "#FF4E4E",
 },

 /* SBER */
 {
  type: "image",
  content: "sber_sreen.jpg",
  project: "SBER",
  bgcolor: "#fff",
 },
 {
  type: "text",
  content: "СБЕР: обучающая платформа",
  project: "SBER",
  bgcolor: "#FFF",
 },
 {
  type: "image",
  content: "sber_ui.jpg",
  project: "SBER",
  bgcolor: "#fff",
 },

 /* ЦИАН */
 {
  type: "image",
  content: "cian_2.webp",
  project: "ЦИАН_2",
  bgcolor: "#0C68F8",
 },
 {
  type: "text",
  content:
   "Переезд с N1 на ЦИАН для риелторов: спроектировали инструкцию, проработали отказы и негативные сценарии, объяснили преимущества, пояснили за топовые фичи. Провели исследования и тесты. В прод выкатывали по областям",
  project: "ЦИАН_2",
  bgcolor: "#FFF",
 },
 {
  type: "text",
  content:
   "Отдельная благодарность pro команде: Максим Пешков – продакт N1, Алиса Каракина – продуктовый редактор b2b Циан и шестерым лпр крупных риэлторских агентств изменивших наше восприятие их болей и ценностей",
  project: "ЦИАН_2",
  bgcolor: "#FFF",
 },
 {
  type: "image",
  content: "cian_1.jpg",
  project: "ЦИАН_1",
  bgcolor: "#0C68F8",
 },
 {
  type: "text",
  content: "Шэр подборкой вариантов от риэлтора → клиенту",
  project: "ЦИАН_1",
  bgcolor: "#FFF",
 },

 /* ill */
 {
  type: "image",
  content: "ill_foil.png",
  project: "ill",
  bgcolor: "#000",
 },
 {
  type: "image",
  content: "ill_skull.jpeg",
  project: "ill",
  bgcolor: "#000",
 },
 {
  type: "image",
  content: "ill_girl_1.png",
  project: "ill",
  bgcolor: "#000",
 },
 {
  type: "image",
  content: "ill_pie.png",
  project: "ill",
  bgcolor: "#000",
 },

 {
  type: "image",
  content: "ill_chicken.png",
  project: "ill",
  bgcolor: "#000",
 },
 {
  type: "image",
  content: "ill_3d.jpeg",
  project: "ill",
  bgcolor: "#000",
 },
 {
  type: "image",
  content: "ill_3d_land.png",
  project: "ill",
  bgcolor: "#000",
 },
 {
  type: "image",
  content: "ill_gif_raft.gif",
  project: "ill",
  bgcolor: "#000",
 },

 /* comics */
 {
  type: "image",
  content: "ill_commics_0.jpeg",
  project: "comics",
  bgcolor: "#fff",
 },
 {
  type: "image",
  content: "ill_commics_1.png",
  project: "comics",
  bgcolor: "#fff",
 },
 {
  type: "image",
  content: "ill_commics_2.jpeg",
  project: "comics",
  bgcolor: "#fff",
 },
 {
  type: "image",
  content: "ill_commics_3.png",
  project: "comics",
  bgcolor: "#fff",
 },
 {
  type: "image",
  content: "ill_commics_4.jpeg",
  project: "comics",
  bgcolor: "#fff",
 },
 {
  type: "image",
  content: "ill_commics_5.jpeg",
  project: "comics",
  bgcolor: "#fff",
 },
 {
  type: "image",
  content: "ill_commics_6_1.jpeg",
  project: "comics",
  bgcolor: "#fff",
 },
 {
  type: "image",
  content: "ill_commics_6_2.jpeg",
  project: "comics",
  bgcolor: "#fff",
 },

 /* музей москвы */
 {
  type: "image",
  content: "20211001_poster_mm_1.png",
  project: "20211001_poster_mm_1",
  bgcolor: "#FAED37",
 },
 {
  type: "image",
  content: "20211001_poster_mm_1_1.webp",
  project: "20211001_poster_mm_1",
  bgcolor: "#FAED37",
 },
 {
  type: "image",
  content: "20211001_poster_mm_1_2.webp",
  project: "20211001_poster_mm_1",
  bgcolor: "#FAED37",
 },
 {
  type: "image",
  content: "20211001_poster_mm_1_3.webp",
  project: "20211001_poster_mm_1",
  bgcolor: "#FAED37",
 },
 {
  type: "image",
  content: "20211001_poster_mm_1_4.webp",
  project: "20211001_poster_mm_1",
  bgcolor: "#FAED37",
 },
 {
  type: "image",
  content: "20211001_poster_mm_1_5.webp",
  project: "20211001_poster_mm_1",
  bgcolor: "#FAED37",
 },
 /* 2 */
 {
  type: "image",
  content: "20211001_poster_mm_2.png",
  project: "20211001_poster_mm_2",
  bgcolor: "#80FF01",
 },
 {
  type: "image",
  content: "20211001_poster_mm_2_1.jpg",
  project: "20211001_poster_mm_2",
  bgcolor: "#80FF01",
 },
 {
  type: "image",
  content: "20211001_poster_mm_2_2.jpg",
  project: "20211001_poster_mm_2",
  bgcolor: "#80FF01",
 },
 {
  type: "image",
  content: "20211001_poster_mm_2_3.jpg",
  project: "20211001_poster_mm_2",
  bgcolor: "#80FF01",
 },
 {
  type: "image",
  content: "20211001_poster_mm_2_4.jpg",
  project: "20211001_poster_mm_2",
  bgcolor: "#80FF01",
 },
 /* 3 */
 {
  type: "image",
  content: "20211001_poster_mm_3.png",
  project: "20211001_poster_mm_3",
  bgcolor: "#000",
 },
 {
  type: "image",
  content: "20211001_poster_mm_3_1.jpg",
  project: "20211001_poster_mm_3",
  bgcolor: "#000",
 },
 {
  type: "image",
  content: "20211001_poster_mm_3_2.jpg",
  project: "20211001_poster_mm_3",
  bgcolor: "#FFC37C",
 },
 {
  type: "image",
  content: "20211001_poster_mm_3_3.jpg",
  project: "20211001_poster_mm_3",
  bgcolor: "#fff",
 },
 {
  type: "image",
  content: "20211001_poster_mm_3_4.jpg",
  project: "20211001_poster_mm_3",
  bgcolor: "#FFC37C",
 },
 {
  type: "image",
  content: "20211001_poster_mm_3_5.jpg",
  project: "20211001_poster_mm_3",
  bgcolor: "#000",
 },

 /* adaperio */
 {
  type: "image",
  content: "adaperio_1.png",
  project: "adaperio",
  bgcolor: "#00B668",
 },
 {
  type: "image",
  content: "adaperio_2.png",
  project: "adaperio",
  bgcolor: "#00B668",
 },
 {
  type: "image",
  content: "adaperio_3.png",
  project: "adaperio",
  bgcolor: "#00B668",
 },
 {
  type: "image",
  content: "adaperio_4.png",
  project: "adaperio",
  bgcolor: "#FFF",
 },

 /* плотные картинки */
 {
  type: "image",
  content: "poster_layout.png",
  project: "poster_layout",
  bgcolor: "#fff",
 },
 {
  type: "image",
  content: "poster_layout_1.jpg",
  project: "poster_layout",
  bgcolor: "#fff",
 },
 {
  type: "image",
  content: "poster_layout_2.jpg",
  project: "poster_layout",
  bgcolor: "#fff",
 },
 {
  type: "image",
  content: "poster_layout_3.jpg",
  project: "poster_layout",
  bgcolor: "#fff",
 },
 {
  type: "image",
  content: "poster_layout_4.jpg",
  project: "poster_layout",
  bgcolor: "#fff",
 },

 /* ПИК */
 {
  type: "image",
  content: "pic_3.jpg",
  project: "ПИК",
  bgcolor: "#FF4114",
  category: "gui",
 },
 {
  type: "image",
  content: "pic_1.png",
  project: "ПИК",
  bgcolor: "#FF4114",
  category: "gui",
 },
 {
  type: "image",
  content: "pic_1_2.png",
  project: "ПИК",
  bgcolor: "#FF4114",
  category: "gui",
 },
 {
  type: "video_noloop",
  content: "pic_arenda.mp4",
  project: "ПИК",
  bgcolor: "#DBDADB",
  category: "gui",
 },

 /* CarPrice */
 {
  type: "image",
  content: "CarPrice_gui_1.webp",
  project: "CarPrice",
  bgcolor: "#209B55",
  category: "gui",
 },
 {
  type: "image",
  content: "CarPrice_gui_2.png",
  project: "CarPrice",
  bgcolor: "#209B55",
  category: "gui",
 },
 {
  type: "text",
  content:
   "CarPrice для дилеров: собрал знания за что клиенты ценят продукт у продажников, за что не негодуют у тех.поддержки, как выбирают и какого мнения у smm и собрали лендинг с копирайтером",
  project: "CarPrice",
  bgcolor: "#fff",
  category: "gui",
 },
 /* реклама  */
 {
  type: "image",
  content: "CarPrice_ad_2.png",
  project: "CarPrice_ad",
  bgcolor: "#000",
  category: "graphic",
 },
 {
  type: "image",
  content: "CarPrice_ad_1.png",
  project: "CarPrice_ad",
  bgcolor: "#fff",
  category: "graphic",
 },

 /* Rate&Goods */
 {
  type: "image",
  content: "Rate&Goods.png",
  project: "Rate&Goods",
  bgcolor: "#3AA04A",
  category: "gui",
 },
 {
  type: "text",
  content:
   "Rate&Goods: общаясь с покупателями в продуктовых магазинах обнаружил актуальность отображения пищевых добавок и аллергенов",
  project: "Rate&Goods",
  bgcolor: "#fff",
  category: "gui",
 },

 /* МЕЛ */
 {
  type: "image",
  content: "mel_gui_1.png",
  project: "МЕЛ",
  bgcolor: "#F9ED32",
  category: "gui",
 },
 {
  type: "text",
  content: "МЕЛ: Экран поиска курсов",
  project: "МЕЛ",
  bgcolor: "#fff",
  category: "gui",
 },

 /* ВГТРК */
 {
  type: "image",
  content: "vgtrk_1.jpeg",
  project: "ВГТРК",
  bgcolor: "#E9FE73",
 },
 {
  type: "image",
  content: "vgtrk_2.jpeg",
  project: "ВГТРК",
  bgcolor: "#E9FE73",
 },
 {
  type: "image",
  content: "vgtrk_3.jpeg",
  project: "ВГТРК",
  bgcolor: "#E9FE73",
 },
 {
  type: "image",
  content: "vgtrk_4.jpeg",
  project: "ВГТРК",
  bgcolor: "#E9FE73",
 },
 {
  type: "text",
  content:
   "Сказочный город: ферма по мотивам мультсериала «Сказочный патруль». gui ･ концепты ･ пропсы ･ 3d ･ анимация",
  project: "ВГТРК",
  bgcolor: "#fff",
 },

 /* TaisiaKorotkova */
 {
  type: "image",
  content: "taisia.png",
  project: "TaisiaKorotkova",
  bgcolor: "#FFF",
 },
 {
  type: "text",
  content: "TaisiaKorotkova.com: gui ･ html ･ css ･ js ･ seo",
  project: "TaisiaKorotkova",
  bgcolor: "#FFF",
 },

 /* видео */
 {
  type: "video",
  content: "TDMovieOut.1.mp4",
  project: "video",
  bgcolor: "#000",
 },
 {
  type: "video",
  content: "20230125_203128_.mp4",
  project: "video",
  bgcolor: "#000",
 },
 {
  type: "video",
  content: "intro.mp4",
  project: "video",
  bgcolor: "#000",
 },

 // OZON
 {
  type: "video",
  content: "OZON_ship_ui.mp4",
  project: "OZON",
  bgcolor: "#000",
 },
 {
  type: "image",
  content: "OZON_19.webp",
  project: "OZON",
  bgcolor: "#FFF",
 },
 {
  type: "image",
  content: "OZON_23.webp",
  project: "OZON",
  bgcolor: "#FFF",
 },
 {
  type: "image",
  content: "OZON_34.webp",
  project: "OZON",
  bgcolor: "#FFF",
 },
 {
  type: "image",
  content: "OZON_48.webp",
  project: "OZON",
  bgcolor: "#FFF",
 },
 {
  type: "image",
  content: "OZON_52.webp",
  project: "OZON",
  bgcolor: "#FFF",
 },
 {
  type: "image",
  content: "OZON_55.webp",
  project: "OZON",
  bgcolor: "#FFF",
 },

 // Izmajlovskij_park
 {
  type: "image",
  content: "Izmajlovskij_park_4.webp",
  project: "Izmajlovskij_park",
  bgcolor: "#F0F0F0",
 },
 {
  type: "image",
  content: "Izmajlovskij_park_1.webp",
  project: "Izmajlovskij_park",
  bgcolor: "#F0F0F0",
 },
 {
  type: "image",
  content: "Izmajlovskij_park_2.webp",
  project: "Izmajlovskij_park",
  bgcolor: "#F0F0F0",
 },
 {
  type: "image",
  content: "Izmajlovskij_park_3.webp",
  project: "Izmajlovskij_park",
  bgcolor: "#F0F0F0",
 },
 {
  type: "text",
  content: "Данный стиль и вёрстка обусловлены слабо видящими пользователями",
  project: "Izmajlovskij_park",
  bgcolor: "#FFF",
 },

 // Aviasales
 {
  type: "image",
  content: "Aviasales_mob.webp",
  project: "Aviasales",
  bgcolor: "#FFF",
 },
 {
  type: "image",
  content: "Aviasales_pc.webp",
  project: "Aviasales",
  bgcolor: "#FFF",
 },
 {
  type: "image",
  content: "Aviasales_props.webp",
  project: "Aviasales",
  bgcolor: "#FFF",
 },

 // ITOGO
 {
  type: "image",
  content: "ITOGO.webp",
  project: "ITOGO",
  bgcolor: "#0166F8",
 },
 {
  type: "image",
  content: "ITOGO_code.webp",
  project: "ITOGO",
  bgcolor: "#000",
 },
];

export default images;
