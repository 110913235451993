import React from "react";
import Menu from "../Menu";
import Gallery from "../Gallery";
import GalleryOne from "../GalleryOne";
import images from "../images";

class Gui extends React.Component {
 handleMenuToggle = () => {
  this.setState((prevState) => ({
   isMenuVisible: !prevState.isMenuVisible,
  }));
 };

 componentDidMount() {
  window.scrollTo(0, 0); // Вставьте код сброса положения прокрутки здесь
 }
 render() {
  return (
   <div>
    <Menu />
    <div className="content">
     

     {/* ARMBUSINESSBANK */}
     <Gallery images={images.filter((item) => item.project === "ABB")} />
     {/* Adaperio */}
     <Gallery images={images.filter((item) => item.project === "adaperio")} />
     {/* ЦИАН */}
     <Gallery images={images.filter((item) => item.project === "ЦИАН_2")} />
     <Gallery images={images.filter((item) => item.project === "ЦИАН_1")} />
     {/* Тася */}
     <Gallery
      images={images.filter((item) => item.project === "TaisiaKorotkova")}
     />
     {/* CarPrice */}
     <Gallery images={images.filter((item) => item.project === "CarPrice")} />
     {/* ПИК */}
     <Gallery images={images.filter((item) => item.project === "ПИК")} />
     {/* СБЕР */}
     <Gallery images={images.filter((item) => item.project === "SBER")} />
     {/* Rate&Goods */}
     <Gallery images={images.filter((item) => item.project === "Rate&Goods")} />
     {/* Izmajlovskij_park */}
     <Gallery
      images={images.filter((item) => item.project === "Izmajlovskij_park")}
     />
     {/* МЕЛ */}
     <Gallery images={images.filter((item) => item.project === "МЕЛ")} />
     {/* ITOGO */}
     <Gallery images={images.filter((item) => item.project === "ITOGO")} />
     {/* ВГТРК */}
     <Gallery images={images.filter((item) => item.project === "ВГТРК")} />
     {/* xtalk.chat */}
     <Gallery images={images.filter((item) => item.project === "xtalk")} />

     {/* OZON */}
     <GalleryOne
      images={images.filter(
       (item) => item.project === "OZON" && item.type === "video"
      )}
     />

     {/* Aviasales */}
     <Gallery images={images.filter((item) => item.project === "Aviasales")} />

     {/* rive */}
     <div className="gallery2">
      <GalleryOne images={images.filter((item) => item.project === "rive1")} />
      <GalleryOne images={images.filter((item) => item.project === "rive2")} />
     </div>

     <GalleryOne
      images={images.filter(
       (item) => item.project === "OZON" && item.type === "video"
      )}
     />
     
     {/* Сбер-сказка */}
     <Gallery images={images.filter((item) => item.project === "01")} />
     <a
      href="https://www.figma.com/proto/F6QyQk574UiWhcxLGn5WPI/20231214_PhilippSedov_SBER?page-id=0%3A1&type=design&node-id=25-160&t=gSLxCdUmoCtzVUMD-0&scaling=contain&starting-point-node-id=25%3A160&hide-ui=1"
      target="_blank"
     >
      прототип
     </a>
    </div>
   </div>
  );
 }
}

export default Gui;