/* import React from "react"
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './App'

const root = ReactDOMClient.createRoot(document.getElementById("root"))
root.render(<App />)
 */


import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

ReactDOM.render(<App />, document.getElementById('root'));


