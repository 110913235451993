/* страница CV */

import React from "react";
// import CVproject from "../CVproject";
// import cv_projects from "../CVprojects";
import Menu from "../Menu";
import Btn from "../Btn";
import "../index.css";
import "../cv.css";


const CV = () => {
 return (
  <div>
   <Menu />
   <div>
    <div className="cv_projects">
     <div className="ava_and_pdf">
      <img
       className="cv_ava"
       src="../philippsedov.webp"
       alt="Филипп Седов фото"
      ></img>
      <div className="cv_pdf">
       <Btn
        href="../cv.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="buttonCV"
        style={{ backgroundColor: "blue", color: "white" }}
       >
        .pdf
       </Btn>
      </div>
     </div>

     <div className="cv_project_text">
      <p>Больше 16 лет в продуктовом и графическом дизайне.</p>
      <p>
       Начинал художником в Skyrim. Пройдя курсы по html, css, анимации,
       3d и окончив архитектурное отделение Художественного Лицея и взяв
       золотую медальку Академии Художеств, пригласили в Game Insight отвечать
       за фоны с анимацией и ui.
      </p>
      <p>
       Пройдя интенсивы в британке по ux и рекламе, пошёл в продуктовые
       агентства и it&#8209;компании отвечать за ux/ui и графический дизайн.
      </p>
      <p>
       Опыт управления командой до 12 человек. Отвечаю за проектирование
       продуктов, фирменный стиль и рекламу: исследования рынков, конкурентов,
       потребностей и опыт пользователей, анализ метрик, ui разработку,
       анимацию, 2D и 3D контент.
      </p>
      <p>
       <h3>
        <pre>
         <br></br>    UX/UI | СБЕР
         <br></br>          | ЦИАН
         <br></br>          | Adaperio
         <br></br>          | ARMBUSINESSBANK
         <br></br>          | Rate&Goods
         <br></br>          | Литрес
         <br></br>          | ПИК
         <br></br>          | МЕЛ
         <br></br>          | ITOGO
         <br></br>  Motion || Тинькофф
         <br></br>         || Game Insight
         <br></br>         || CarPrice
         <br></br>         || PARI
         <br></br>         || ВГТРК
         <br></br>         |  tutu
         <br></br>Graphic ||  X5 Group
         <br></br>        ||  OZON
         <br></br>        |   Ростелеком
         <br></br>        |   Riot Games
         <br></br>        |   Bethesda
         <br></br>        |   DREAME
         <br></br>        |   Музей Москвы
        </pre>
       </h3>
      </p>
      <p>
       Сотрудничество с владельцами, инвесторами, маркетингом, pr, поддержкой
       и инженерами по сбору требований, нюансов и бизнес целей, для составления
       ранжирования фичей по рентабельности.
      </p>
      <p>
       Координация работы с различными командами и заинтересованными сторонами,
       обеспечение эффективности процесса разработки и соблюдение сроков.
      </p>
      <p>
       Проведение исследований и анализ пользователей для понимания
       потребностей и целей пользователей, а также болевых точек и непоняток
       опросами, интервью и юзабилити&#8209;тестированиями с координацией
       сеансов тестирования, сбором данных и анализом результатов.
      </p>
      Разработка пользовательских сценариев, прототипов с анимацией и финальных
      макетов интерфейсов, рассылок, рекламы, презентаций, фин.отчётов.
      <p>+ Ведение документации.</p>
      <hr></hr>
      <p></p>
      <p>
       <h3>UX/UI Design:</h3> Проектирование веб&#8209;сайтов и мобильных
       приложений с акцентом на удобство использования и визуальную
       привлекательность.
       <p></p>
       <h3>Animation and 3D Content:</h3> Создание динамичных и интерактивных
       анимаций, 3D&#8209;моделирование и моушн&#8209;дизайн.
       <p></p>
       <h3>Programming:</h3> Разработка фронтенда с использованием HTML, CSS,
       JS, React, а также опыт в Python&#8209;разработке.
       <p></p>
       <h3>Data&#8209;Driven Design:</h3> Проектирование, учитывающее метрики,
       оптимизацию и результативность, а не только визуальный аспект.
       <p></p>
       <h3>Efficiency Enthusiast:</h3> Мои проекты не только красивы,
       но и оптимизированы для эффективной разработки и максимального комфорта
       для разработчиков.
       <p></p>
       <h3>Adaptive Design:</h3> Специализация на создании интерфейсов,
       учитывающих потребности всех пользователей, включая слабовидящих
       и дальтоников.
      </p>
      {/* <p>
       {cv_projects.map((project, index) => (
        <CVproject key={index} {...project} />
       ))}
      </p>
      <hr></hr> */}
      <hr></hr>
      <p></p>
      <p>
       <h3>Образование:</h3>
       <p></p>
       <li>
        В 14 лет курсы HTML, CSS, Photoshop, Illustrator, Flash. Тогда же
        и первые заказы.
       </li>
       <li>
        Архитектурное отделение МЦХШ (МАХЛ) РАХ, взял золотую медальку
        Академии Художеств за дипломные работы.
       </li>
       <li>Интенсивы в Британке по UXUI и рекламе.</li>
       <li>Работа с арт&#8209;директором Сноб.</li>
       <li>Курсы торговле на бирже в Открытие Брокер.</li>
       <li>Cinema4d, Nuke, Houdini, TouchDesigner, Python, JS, SQL</li>
      </p>
      <hr></hr>
      <p></p>
      <p>
       <h3>Вклад в сообщество:</h3>
       <li>
        Плагин для фигмы:{" "}
        <a
         href="https://www.figma.com/community/plugin/1308225272789067035/"
         target="_blank"
        >
         %Percent Area
        </a>
       </li>

       <li>
        <a href="/time" target="_blank">
         Калькулятор расчёта времени на задачу
        </a>
       </li>
      </p>
      <hr></hr>
      <p></p>
      <p>
       <h3>Контакты:</h3>
       <li>
        <a
         href="https://t.me/philippsedov"
         target="_blank"
         rel="noopener noreferrer"
        >
         telegram
        </a>
       </li>
       {/* <li>
        <a
         href="https://github.com/philippsedov"
         target="_blank"
         rel="noopener noreferrer"
        >
         github
        </a>
        </li> */}
      </p>
     </div>
     {/* {cv_projects.map((project, index) => (
      <CVproject key={index} {...project} />
     ))} */}
    </div>
   </div>
  </div>
 );
};

export default CV;
