import React, { useState } from "react";

const Gallery = ({ images }) => {
 const [currentIndex, setCurrentIndex] = useState(0);

 const handleSlideClick = (e) => {
  const slideWidth = e.target.offsetWidth;
  const clickPosition = e.clientX - e.target.getBoundingClientRect().left;
  const isRightClick = clickPosition > slideWidth / 2.8;

  if (isRightClick) {
   setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  } else {
   setCurrentIndex(
    (prevIndex) => (prevIndex - 1 + images.length) % images.length
   );
  }
 };

 const handleMouseMove = (e) => {
  const slideWidth = e.target.offsetWidth;
  const clickPosition = e.clientX - e.target.getBoundingClientRect().left;
  const isRightClick = clickPosition > slideWidth / 2.8;
  if (isRightClick) {
   e.target.style.cursor = "url('../arrow_right.svg'), e-resize";
  } else {
   e.target.style.cursor = "url('../arrow_left.svg'), w-resize";
  }
 };

 const getCurrentSlide = () => {
  const slide = images[currentIndex];
  const backgroundStyle = { backgroundColor: slide.bgcolor };
  if (slide.type === "image") {
   return (
    <img
     src={`../img/${slide.content}`}
     alt={slide.project}
     onClick={handleSlideClick}
     onMouseMove={handleMouseMove}
     style={{
      width: "100vw",
      height: "80vh",
      objectFit: "contain",
      objectPosition: "center",
      display: "block",
      ...backgroundStyle,
     }}
    />
   );
  } else if (slide.type === "text") {
   return (
    <div
     style={{
      height: "80vh",
      padding: "8.33vh",
      objectPosition: "center сenter",
      display: "flex",
      width: "100vw",
      alignItems: "center",
      ...backgroundStyle,
     }}
     onClick={handleSlideClick}
     onMouseMove={handleMouseMove}
    >
     <div style={{ whiteSpace: "pre-line" }}>
      {slide.content}
     </div>
    </div>
   );
  } else if (slide.type === "video") {
   return (
    <video
     src={`../img/${slide.content}`}
     alt={slide.project}
     onClick={handleSlideClick}
     onMouseMove={handleMouseMove}
     style={{
      width: "100vw",
      height: "80vh",
      objectFit: "contain",
      objectPosition: "center",
      display: "block",
      ...backgroundStyle,
     }}
     autoPlay
     loop
    />
   );
  } else if (slide.type === "video_noloop") {
   return (
    <video
     src={`../img/${slide.content}`}
     alt={slide.project}
     onClick={handleSlideClick}
     onMouseMove={handleMouseMove}
     style={{
      width: "100vw",
      height: "80vh",
      objectFit: "contain",
      objectPosition: "center",
      display: "block",
      ...backgroundStyle,
     }}
     autoPlay
    />
   );
  }
  return null;
 };

 return (
  <div style={{ maxWidth: "100vw" }}>
   <div>{getCurrentSlide()}</div>
  </div>
 );
};

export default Gallery;
