// Time.js

import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import "../index.css";
import "./page.css";

const Time = () => {
 const [t1, setT1] = useState(1);
 const [t2, setT2] = useState(3);
 const [t3, setT3] = useState(9);
 const [result, setResult] = useState(5);
 const [resultTime, setResultTime] = useState({
  hoursPositive: 2,
  minutesPositive: 20,
  hoursNegative: 5,
  minutesNegative: 0,
 });

 const onInputChange = (event) => {
  const { id, value } = event.target;
  const parsedValue = Number(value);

  if (!isNaN(parsedValue)) {
   switch (id) {
    case "t1":
     setT1(parsedValue);
     break;
    case "t2":
     setT2(parsedValue);
     break;
    case "t3":
     setT3(parsedValue);
     break;
    default:
     break;
   }
  }
 };

 useEffect(() => {
  const t = (t1 + t2 * 4 + t3) / 6;
  const r = (t3 - t1) / 6;
  const trn = t + r;
  const trp = t - r;

  const roundTime = (time) => Math.ceil(time * 10) / 10;

  const calculateTime = (time) => {
   const hours = Math.floor(time);
   const minutes = Math.round((time * 60) % 60);
   return {
    hours,
    minutes: minutes === 0 ? "00" : minutes,
   };
  };

  setResult(roundTime(trn));
  setResultTime({
   hoursPositive: calculateTime(trp).hours,
   minutesPositive: calculateTime(trp).minutes,
   hoursNegative: calculateTime(trn).hours,
   minutesNegative: calculateTime(trn).minutes,
  });
 }, [t1, t2, t3]);

 return (
  <div className="container">
   <Menu />
   <div
    className="content"
    style={{
     width: "100%",
     height: "80vh",
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     gap: "2ch",
     flexDirection: "column",
    }}
   >
    <h1 className="title">Prediction of time to complete a task</h1>
    <p style={{ maxWidth: "37ch"}}>
     <label className="optimistic">
      optimistic duration
      <input
       type="number"
       id="t1"
       className="input_time"
       value={t1}
       onChange={onInputChange}
      />
     </label>
     <br />
     <label className="realistic">
      realistic
      <input
       type="number"
       id="t2"
       className="input_time"
       value={t2}
       onChange={onInputChange}
      />
     </label>
     <br />
     <label className="pessimistic">
      pessimistic
      <input
       type="number"
       id="t3"
       className="input_time"
       value={t3}
       onChange={onInputChange}
      />
     </label>
     <br />
     most likely <span id="result">{result}</span>
     <br />
     <span id="result_2">{resultTime.hoursPositive}</span>:
     <span id="result_3">{resultTime.minutesPositive}</span> –{" "}
     <span id="result_4">{resultTime.hoursNegative}</span>:
     <span id="result_5">{resultTime.minutesNegative}</span>
    </p>

    <p>
     <span className="formula"> ( t1 + t2×4 + t3 ) ÷ 6 ± risk </span>
     <br />
     <span className="formula"> risk = ( t3 - t1 ) ÷ 6 </span>
    </p>
   </div>
  </div>
 );
};

export default Time;
