import React, { useState, useEffect } from "react";
import Menu from "../Menu";
import "../index.css";
import "./page.css";
import Btn from "../Btn";

const PercentArea = () => {
 const [widthPoster, setWidthPoster] = useState("1920");
 const [heightPoster, setHeightPoster] = useState("1080");
 const [widthSign, setWidthSign] = useState("100");
 const [heightSign, setHeightSign] = useState("100");
 const [result, setResult] = useState("322.00 × 322.00");
 const [targetPercentage, setTargetPercentage] = useState(5);
 const [fontSize, setFontSize] = useState(16);

 useEffect(() => {
  calculateSignSize();
 }, [widthPoster, heightPoster, widthSign, heightSign, targetPercentage]);

 useEffect(() => {
  // Calculate font size based on the length of the result text
  const resultLength = result.length || 9; // Avoid division by zero
  const newFontSize = Math.min(Math.max(16, 599 / resultLength), 50);
  console.log("resultLength", resultLength);
  console.log("newFontSize", newFontSize);
  setFontSize(newFontSize);
 }, [result]);

 useEffect(() => {
  const timer = setTimeout(() => {
   calculateSignSize();
  }, 200);

  return () => clearTimeout(timer);
 }, [widthPoster, heightPoster, widthSign, heightSign, targetPercentage]);

 const calculateSignSize = () => {
  const widthPosterValue = parseInt(widthPoster);
  const heightPosterValue = parseInt(heightPoster);
  let widthSignValue = parseInt(widthSign);
  let heightSignValue = parseInt(heightSign);

  // Проверка на отрицательные значения
  if (
   widthPosterValue <= 0 ||
   heightPosterValue <= 0 ||
   widthSignValue <= 0 ||
   heightSignValue <= 0
  ) {
   setResult("Будь на позитиве");
   return;
  }

  // Проверка на заполненность всех полей
  if (
   !widthPosterValue ||
   !heightPosterValue ||
   !widthSignValue ||
   !heightSignValue
  ) {
   setResult("");
   return;
  }

  const areaPoster = widthPosterValue * heightPosterValue;
  let areaSign = widthSignValue * heightSignValue;

  // Изначальное соотношение сторон знака
  const aspectRatio = widthSignValue / heightSignValue;

  // Вычисление целевой площади знака на основе процента
  const targetArea = (targetPercentage / 100) * areaPoster;

  // Если исходная площадь знака меньше целевой, увеличиваем размеры знака
  while (areaSign < targetArea) {
   widthSignValue++;
   heightSignValue = widthSignValue / aspectRatio; // Сохраняем соотношение сторон
   areaSign = widthSignValue * heightSignValue;
  }

  // Если исходная площадь знака больше целевой, уменьшаем но не сильно
  while (areaSign > targetArea * 1.1) {
   widthSignValue--;
   heightSignValue = widthSignValue / aspectRatio; // Сохраняем соотношение сторон
   areaSign = widthSignValue * heightSignValue;
  }

  setResult(`${widthSignValue.toFixed(2)} × ${heightSignValue.toFixed(2)}`);
 };

 const handleWidthPosterChange = (e) => {
  setWidthPoster(e.target.value);
  calculateSignSize();
 };

 const handleHeightPosterChange = (e) => {
  setHeightPoster(e.target.value);
  calculateSignSize();
 };

 const handleWidthSignChange = (e) => {
  setWidthSign(e.target.value);
  calculateSignSize();
 };

 const handleHeightSignChange = (e) => {
  setHeightSign(e.target.value);
  calculateSignSize();
 };

 const handleTargetPercentageChange = (e) => {
  const newTargetPercentage = parseInt(e.target.value);
  if (newTargetPercentage <= 0) {
   setResult("Будь на позитиве");
   return;
  }
  setTargetPercentage(newTargetPercentage);
  calculateSignSize();
 };

 return (
  <div className="container">
   <Menu />
   <div
    className="content"
    style={{
     width: "100%",
     height: "50vh",
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     gap: "1ch",
     flexDirection: "column",
    }}
   >
    <h1 className="title">
     <input
      style={{ width: "40px", fontWeight: "bold", color: "black" }}
      type="number"
      id="target_percentage"
      value={targetPercentage}
      onChange={handleTargetPercentageChange}
     />
      процентов от площади
    </h1>

    <div style={{ display: "flex", gap: "20px" }}>
     <div>
      <label htmlFor="width_poster">Размеры a</label>
      <div style={{ display: "flex", flexDirection: "row" }}>
       <input
        type="number"
        id="width_poster"
        placeholder="W"
        value={widthPoster}
        onInput={handleWidthPosterChange}
       />
       <input
        type="number"
        id="height_poster"
        placeholder="H"
        value={heightPoster}
        onInput={handleHeightPosterChange}
       />
      </div>
     </div>
    </div>

    <div>
     <label htmlFor="width_sign">Размеры b</label>
     <div style={{ display: "flex", flexDirection: "row" }}>
      <input
       type="number"
       id="width_sign"
       placeholder="w"
       value={widthSign}
       onInput={handleWidthSignChange}
      />
      <input
       type="number"
       id="height_sign"
       placeholder="h"
       value={heightSign}
       onInput={handleHeightSignChange}
      />
     </div>
    </div>
    <label>
     a × {targetPercentage}% = <br />
     <span id="result_5percent" style={{ fontSize: `${fontSize}px` }}>
      {result}
     </span>
    </label>
   </div>
   <div className="video-container">
    <Btn
     href="https://www.figma.com/community/plugin/1308225272789067035/"
     target="_blank"
     className="buttonCV"
    >
     Плагин Figma
    </Btn>

    <video autoPlay loop muted className="video">
     <source src="./img/Percent_Area/Percent_Area.webm" type="video/webm" />
     <source src="./img/Percent_Area/Percent_Area.mp4" type="video/mp4" />
     Your browser does not support the video tag.
    </video>
   </div>
  </div>
 );
};

export default PercentArea;
