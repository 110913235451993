import React from "react";
import Menu from "../Menu";
import Gallery from "../Gallery";
import GalleryOne from "../GalleryOne";
import images from "../images";
import "../index.css";
import "./page.css";

class Zabavki extends React.Component {
 handleMenuToggle = () => {
  this.setState((prevState) => ({
   isMenuVisible: !prevState.isMenuVisible,
  }));
 };

 componentDidMount() {
  window.scrollTo(0, 0); // Вставьте код сброса положения прокрутки здесь
 }
 render() {
  return (
   <div className="container">
    <Menu />
    <div className="content" style={{ paddingTop: "10vh", paddingBottom: "20vh", height: "auto", gap: "10vh" }}>
     <div>
      <a href="/PercentArea">
       <h1 style={{ paddingBottom: "1ch" }}>%Percent Area – меняет площадь на процент от фрейма</h1>
       <video autoPlay loop muted className="video">
        <source src="./img/Percent_Area/Percent_Area.webm" type="video/webm" />
        <source src="./img/Percent_Area/Percent_Area.mp4" type="video/mp4" />
        Your browser does not support the video tag.
       </video>
      </a>
     </div>
     {/* Калькулятор расчёта времени на задачу */}
     <div>
      <a href="/time">
      <h1 style={{ paddingBottom: "1ch" }}>Калькулятор расчёта времени на задачу</h1>
       <img src="./img/time.png" alt="Скриншот калькулятора" className="video" style={{ borderRadius: "2ch", boxShadow: "0 0 10px rgba(1, 0, 1, 0.1)"}}/>
      </a>
     </div>
    </div>
   </div>
  );
 }
}

export default Zabavki;
