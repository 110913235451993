import React from "react";
import Menu from "../Menu";
import "../index.css";
import "./page.css";

class ColorFormula extends React.Component {
 handleMenuToggle = () => {
  this.setState((prevState) => ({
   isMenuVisible: !prevState.isMenuVisible,
  }));
 };

 componentDidMount() {
  window.scrollTo(0, 0);
 }
 render() {
  return (
   <div className="container">
    <Menu />
    <div
     className="content"
     style={{
      paddingTop: "10vh",
      paddingBottom: "20vh",
      height: "auto",
      gap: "10vh",
     }}
    >
     <div>
      <div style={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center", gap: "2ch" }}>
       <img
        src="./img/logo_colour_formula.png"
        alt="logo addon"
        style={{
         width: "125px",
         borderRadius: "2ch",
         boxShadow: "0 0 10px rgba(1, 0, 1, 0.1)",
        }}
       />
       <h1 style={{ paddingBottom: "1ch" }}>
        Color formula – google spreadsheet add-on
       </h1>
       </div>
       <h2 style={{ paddingTop: "3ch" }}>Privacy Policy</h2>

       <div>
        My plugin does not collect any user data. Therefore, there is no data to
        share with third parties.
       </div>
      

      <div>
       <a href="/zabavki">My other stuff for all</a>
      </div>
     </div>
    </div>
   </div>
  );
 }
}

export default ColorFormula;


