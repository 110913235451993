import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";

import CV from "./pages/CV";

import Gui from "./pages/Gui";
import Graphic from "./pages/Graphic";
import PercentArea from "./pages/PercentArea";
import Time from "./pages/Time";
import Zabavki from "./pages/Zabavki";
import Selection_Bear from "./pages/selection/selection_20231206_bear/Selection_20231206_Bear";
import ColorFormula from "./pages/add_google_Color_formula";

function App() {
 return (
  <Router>
   <Routes>
    <Route path="/" element={<Home />} />
    <Route path="*" element={<Home />} />

    <Route path="/cv" element={<CV />} />

    {/* Меню */}
    <Route path="/gui" element={<Gui />} />
    <Route path="/graphic" element={<Graphic />} />
    <Route path="/zabavki" element={<Zabavki />} />

    {/* %Percent Area */}
    <Route path="/LawScale" element={<PercentArea />} />
    <Route path="/5" element={<PercentArea />} />
    <Route path="/precent" element={<PercentArea />} />
    <Route path="/law" element={<PercentArea />} />
    <Route path="/scale" element={<PercentArea />} />
    <Route path="/Percent_Area" element={<PercentArea />} />
    <Route path="/Percent Area" element={<PercentArea />} />
    <Route path="/PercentArea" element={<PercentArea />} />
    <Route path="/Percent-Area" element={<PercentArea />} />
    <Route path="/%" element={<PercentArea />} />

    {/* color_formula */}
    <Route path="/color_formula" element={<ColorFormula />} />
    <Route path="/add_color_formula" element={<ColorFormula />} />

    {/* time */}
    <Route path="/time" element={<Time />} />

    {/* selection */}
    <Route
     path="/отбор_вариантов_логотипа_для_Романа"
     element={<Selection_Bear />}
    />
    {/* <Route path="/ImageComparison" element={<ImageComparison />} /> */}
   </Routes>
  </Router>
 );
}

export default App;

// добавить новые работы
// добавить картинки для портретного режима
// починить скрол и парномирование при зуме на мобильных, чтобы появление бара не сдвигало сайт


// npm run start
// npm run build
