import React from "react";
import { Link } from "react-router-dom";

function Btn(props) {
 return (
  <Link to={props.href} className={props.className} target={props.target} rel={props.rel}>
   {props.children}
  </Link>
 );
}

export default Btn;